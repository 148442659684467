
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util, getExcelJSONDataByList, getPageAPIs } from "@/common/util";
import { PoolOrderListAPI } from "@/common/api/marketManage/poolOrderList";

// import { util } from "vue/types/umd";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import ZlSelect from "@/components/third-custom-components/select";

import ZlOption from "@/components/third-custom-components/option";

import { excuteDownloadExport2Excel } from "@/common/xlsx";

import {
  shareConfirmOptions,
  shareShopType2ch,
  toChineseNumber,
  // defaultWeekList,
  getCommonList,
} from "@/common/config";

const defaultOrderItem = {
  market_pool_id: '', // 球桌型号
  number: "", // 订单球桌数量
  actual_price: "", // 订单实际金额
  unit_price: "", // 订单单桌单价
  source_date: "", // 线索日期
};



function createOrderItemForm(originItem: any = null): any {
  const item = {
    ...DeepLoop.deepClone(defaultOrderItem, false),
    ...(originItem ? DeepLoop.deepClone(originItem, false) : {}),
  };

  return item;
}




// 暴露的API 列表
export const pageApiList = getPageAPIs(PoolOrderListAPI);

console.log(pageApiList, "pageApiList");

const defaultForm = {


  time: [], // 线索获取日期


};

// 业务类型 1熊猫球社 2麦力士
// const bussinessType2Ch: string[] = ["", "熊猫球社", "麦力士"];

const defaultForms = {
  // origin_price: "",
  // sale_price: "",

  order_date: "",

  child: [createOrderItemForm(null)],

  first_price: "", // 首次缴纳定金
  team: "", // 订单所属小组
  source: "", // 线索来源
  conversion: "", // 订单转化人

  // dataStatistics_time_id: "",
  // singleMaxCountType: '0',
  // shop_id: "",
  // maxLimitCount: ''
};

// const defaultSearchVenuePageOption = {
//   page: 1,
//   limit: 10,
//   isRearchBottom: false,
// };

const dataStatisticDialogTitle2ch: any = {
  "new-pool-order": "新增",
  "edit-pool-order": "编辑",
};

// 最大有效期
// const maxExpiredDays = 9999;

// // 最大时长 单位分钟
// const maxTimeDuration = 24 * 60;

// // dataStatistic 球桌订单分属类别
// const dataStatisticShopType2ch: any = [
//   ...shareShopType2ch
// ]

@Component({
  filters: {
    // showBussinessTypeName(type: number) {
    //   return bussinessType2Ch[type] || "未知";
    // },

  },
  components: {
    ZlSelect,
    ZlOption,
  },
})
export default class PoolOrderListManage extends Vue {
  private form: any = {
    // dataStatisticId: "",
    shop_name: "",
    // time: [],
    // startDate: "",
    // endDate: "",
  };
  private forms: any = {
    // maxType: "0",
    // ...defaultForms
  };

  private deepLoopUtil: any = {};

  // private merchantApi: any;

  // private shopTypeList: any[] = [
  //   {
  //     label: "台球",
  //     value: 1,
  //   },
  //   {
  //     label: "棋牌",
  //     value: 2,
  //   },
  // ];

  private poolOrderListApi = new PoolOrderListAPI();
  // private dataStatisticTimeDurationAPI = new PoolOrderListTimeDurationManageAPI();

  // private chooseWeekDayList: any[] = []

  // private bussinessTypeList: any[] = [
  //   // {
  //   //   label: '全部',
  //   //   value: '',
  //   // },
  //   ...getCommonList(bussinessType2Ch, 1),
  // ];

  // private chooseClueSources: any[] = [];

  // private refreshClueSourceList() {
  //   this.poolOrderListApi.getClueSourceList(
  //     {},
  //     (res: any) => {
  //       const { list } = res.data || {};

  //       if (Array.isArray(list)) {
  //         this.chooseClueSources = list;
  //       } else {
  //         this.chooseClueSources = [];
  //       }
  //     },
  //     (err: any) => {}
  //   );
  // }

  constructor() {
    super();

    this.deepLoopUtil = new DeepLoop();

    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };

    this.forms = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    // this.searchVenuePageOption = {
    //   ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
    // };

    // this.chooseWeekDayList = [
    //   ...this.deepLoopUtil.deepCopy(defaultWeekList, false)
    // ]

    // this.merchantApi = new MerchantApi();
  }

  private created(): void {

    this.getPoolOrderList();

    // this.refreshPoolOrderListTimeDurations();

    // this.refreshTableClass();
  }
  private restForm(): void {
    this.form = {
      ...this.deepLoopUtil.deepCopy(defaultForm),
    };
    // this.form = new Util().clearObject(this.form);
    // this.$router.replace("/orderList");
    this.getPoolOrderList();
  }

  //搜索
  private search(): void {
    this.tablePage.page = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getPoolOrderList();
  }

  private tableData: any[] = [];

  private mergeCells: any[] = [];

  //dialog
  // private dialogFormVisible: boolean = false;
  private formLabelWidth: string = "100px";
  private formSubLabelWidth: string = '90px';
  // private options: any = [];

  // private clearObject(): void {
  //   this.dialogFormVisible = false;
  //   this.forms = new Util().clearObject(this.forms);
  // }
  private tablePage: any = {
    count: 0,
    page: 1,
    prepage: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
    totalPage: 0,
  };
  // //翻页
  private handlePageChange(val: any) {
    this.tablePage.page = val.currentPage;
    this.tablePage.prepage = val.pageSize;
    this.getPoolOrderList();
  }

  // private allPoolOrderListTimeDurations: any[] = [];

  // private refreshPoolOrderListTimeDurations() {
  //   return new Promise((resolve, reject) => {
  //     this.poolOrderListApi.getPoolOrderListTimeDurations(
  //       {
  //         page: 1,
  //         limit: 999999,
  //       },
  //       (res: any) => {
  //         const { data: allPoolOrderListTimeDurationList } = (
  //           res ? res.data : {}
  //         ) as any;

  //         this.allPoolOrderListTimeDurations = allPoolOrderListTimeDurationList || [];

  //         resolve(allPoolOrderListTimeDurationList);
  //       },
  //       (err: any) => {
  //         reject(err);
  //       }
  //     );
  //   });
  // }

  // private allTableCategoryList: any[] = [];

  // private refreshTableClass() {
  //   return new Promise((resolve, reject) => {
  //     this.poolOrderListApi.poolTableCategory(
  //       {},
  //       (res: any) => {
  //         const { list: allTableCategoryList } = (res ? res.data : {}) as any;
  //         // this.tableData = res.data.list;
  //         // this.tablePage.totalCount = res.data.count;

  //         this.allTableCategoryList = allTableCategoryList;

  //         resolve(allTableCategoryList);
  //       },
  //       (err: any) => {
  //         reject(err);
  //       }
  //     );
  //   });
  // }

  private removeLastOrderItem() {
    const forms = this.forms;

    if (Array.isArray(forms.child) && forms.child.length > 0) {
      forms.child.pop();
    }
  }

  private addOrderItem() {
    const forms = this.forms;

    if (Array.isArray(forms.child)) {
      forms.child.push(createOrderItemForm(null));
    }
  }

  private addOrUpdatePoolOrderListTitle: string = "球桌订单";

  private dialogFormVisible: boolean = false;

  // 拉起球桌订单弹框
  private handleWakePoolOrderListDialog(mode: string) {
    // if (mode == 'new-pool-order') { // 新增球桌订单

    // }
    this.refreshPoolBrands()

    this.dataStatisticDialogMode = mode;
    this.addOrUpdatePoolOrderListTitle =
      dataStatisticDialogTitle2ch[mode] || "球桌订单";

    this.initPoolOrderListForm(mode);

    this.dialogFormVisible = true;
  }

  private choosePoolBrands: any[] = []

  private refreshPoolBrands() {
    this.poolOrderListApi.getPoolBrands({}, (res: any) => {
      const {
        list = []
      } = res.data || {}

      this.choosePoolBrands = list
    }, () => {

    })
  }

  private currentPoolOrderListItem: any = null;

  private dataStatisticDialogMode: string = "";

  private async initPoolOrderListForm(mode: string) {
    const forms: any = {
      ...this.deepLoopUtil.deepCopy(defaultForms, false),
    };

    if (mode == "edit-pool-order") {
      // 编辑
      // await this.getMoreVenueList(true);

      // await this.refreshPoolOrderListTimeDurations();

      // await this.refreshTableClass();

      const currentPoolOrderListItem =
        this.currentPoolOrderListItem;

      const {
        _originCell: originCell,
      } = currentPoolOrderListItem || {};

      console.log(currentPoolOrderListItem, 'currentPoolOrderListItem')
      console.log(originCell, 'originCell')

      const {
        id,
        order_date,
        first_price = '',
        team = '',
        source = '',
        conversion = '',
        child: children = []
      } = originCell || {}

      const editChilds = [...children]

      // 去掉原有汇总项
      // editChilds.pop()

      Object.assign(forms, {
        order_date,
        first_price,
        team,
        source,
        conversion,
        child: editChilds.map((item: any) =>
          createOrderItemForm(item)
        ),
       
      });

      forms.id = id;
    }

    console.log(forms, "forms");

    // console.log(this.venueList, "this.venueList");

    this.forms = forms;
  }

  private handleWakeEditPoolOrderListDialog(molixOrderItem: any) {
    this.currentPoolOrderListItem = molixOrderItem;

    this.handleWakePoolOrderListDialog("edit-pool-order");
  }

  private handleWakeDelMolixOrderItem(molixOrderItem: any) {
    this.$confirm("确认删除该球桌订单？", '', {
      ...(shareConfirmOptions || {})
    }).then(() => {
      this.poolOrderListApi.deletePoolOrderListItem({
        id: molixOrderItem.id
      }, () => {
        this.getPoolOrderList();
      }, () => {

      })
    })
  }

  // private venueList: any[] = [
  //   // {
  //   //   id: 1,
  //   //   label: '测试'
  //   // },
  //   // {
  //   //   id: 2,
  //   //   label: '测试2'
  //   // }
  // ];

  // private searchVenueLoading: boolean = false;

  // private remoteGetVenueQueryString: string = "";

  // private remoteSearchVenueTimer: any = null;

  // private searchVenuePageOption: any = {};

  // private initRemoteGetVenuePageOption() {
  //   this.searchVenuePageOption = {
  //     ...this.deepLoopUtil.deepCopy(defaultSearchVenuePageOption, false),
  //   };
  // }

  // private getSearchVenueParams() {
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const remoteGetVenueQueryString = this.remoteGetVenueQueryString;

  //   const { page, limit } = searchVenuePageOption;

  //   const params: any = {
  //     page,
  //     limit,
  //   };

  //   if (remoteGetVenueQueryString && remoteGetVenueQueryString.trim()) {
  //     params.name = remoteGetVenueQueryString.trim();
  //   }

  //   return params;
  // }

  // private getMoreVenueList(refresh: boolean = true) {
  //   return new Promise((resolve: Function, reject: Function) => {
  //     if (refresh) {
  //       this.initRemoteGetVenuePageOption();
  //     }

  //     const params = this.getSearchVenueParams();

  //     this.poolOrderListApi.searchVenueList(
  //       params,
  //       (res: any) => {
  //         console.log(res, "res");

  //         const venueList: any[] = res.data || [];

  //         this.venueList = refresh
  //           ? venueList
  //           : this.venueList.concat(venueList);

  //         if (Array.isArray(res.data) && res.data.length == 0) {
  //           this.searchVenuePageOption.isRearchBottom = true;
  //         }
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         resolve();
  //       },
  //       () => {
  //         this.searchVenueLoading = false;

  //         this.addVenueListLoading = false;

  //         reject();
  //       }
  //     );
  //   });
  // }

  // // 第一次搜索 无关键字
  // private handleFirstGetVenueList() {
  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 清空搜索
  // private handleClearGetVenueList() {
  //   this.remoteGetVenueQueryString = "";

  //   this.searchVenueLoading = true;

  //   this.getMoreVenueList(true);
  // }

  // // 远程搜索
  // private remoteGetVenueMethod(query: string) {
  //   query = query.trim ? query.trim() : "";

  //   if (query !== "") {
  //     this.remoteGetVenueQueryString = query;
  //     clearTimeout(this.remoteSearchVenueTimer);

  //     this.remoteSearchVenueTimer = setTimeout(() => {
  //       this.searchVenueLoading = true;

  //       this.getMoreVenueList();
  //       // searchStu((res) => {
  //       //   const {
  //       //     students,
  //       //     total_count
  //       //   } = res || {}

  //       //   this.remoteAllStudents = [
  //       //     ...(students || [])
  //       //   ]

  //       //   this.remoteStudentTotalCount = total_count || 0

  //       //   this.remoteGetStudentLoading = false
  //       // }, {
  //       //   kw: query,
  //       //   ...this.getRemoteGetStudentPageOptionParams()
  //       // }, () => {
  //       //   this.remoteGetStudentLoading = false
  //       // })
  //     }, 300);
  //     // setTimeout(() => {
  //     //   this.remoteGetStudentLoading = false;
  //     //   this.options = this.list
  //     // }, 200);
  //   } else {
  //     this.remoteGetVenueQueryString = "";
  //     this.venueList = [];
  //   }
  // }

  // private addVenueListLoading: boolean = false;

  // private remoteAddVenueMethod() {
  //   if (this.addVenueListLoading) return;
  //   const searchVenuePageOption = this.searchVenuePageOption;

  //   const { isRearchBottom } = searchVenuePageOption;

  //   if (isRearchBottom) return;

  //   this.searchVenuePageOption.page++;

  //   this.addVenueListLoading = true;

  //   this.getMoreVenueList(false);
  // }

  // // 原价限制
  // private handleLimitOriginPrice(val: string) {
  //   Util.limitDigitalnput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "origin_price", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "origin_price", new_val);
  //     },
  //     2
  //   );
  // }

  // // 售价限制
  // private handleLimitSlaePrice(val: string) {
  //   Util.limitDigitalnput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "sale_price", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "sale_price", new_val);
  //     },
  //     2
  //   );

  // }

  // 有效期
  // private handleLimitExpiredDays(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       const limit_val = Math.min(Number(new_val), maxExpiredDays).toString();
  //       this.$set(this.forms, "expired_days", limit_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "expired_days", new_val);
  //     }
  //   );
  // }

  // // 时长
  // private handleLimitTimeDuration(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       const limit_val = Math.min(Number(new_val), maxTimeDuration).toString();
  //       this.$set(this.forms, "timeDuration", limit_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "timeDuration", new_val);
  //     }
  //   );
  // }

  // private handleLimitSingleMaxCount(val: string) {
  //   Util.limitIntegetInput(
  //     val,
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     },
  //     (new_val: string) => {
  //       this.$set(this.forms, "maxLimitCount", new_val);
  //     }
  //   );
  // }

  private sumbitPoolOrderListLoading: boolean = false;

  // 提交球桌订单配置
  private handleSumbitPoolOrderList() {
    const dataStatisticDialogMode = this.dataStatisticDialogMode;

    const params = this.getPoolOrderListFormsParams(
      dataStatisticDialogMode
    );

    console.log(params, "params");
    if (params === false) return;

    this.sumbitPoolOrderListLoading = true;
    switch (dataStatisticDialogMode) {
      case "new-pool-order":
        this.poolOrderListApi.addPoolOrderList(
          params,
          () => {
            this.getPoolOrderList();
            this.dialogFormVisible = false;
            this.sumbitPoolOrderListLoading = false;
          },
          () => {
            this.sumbitPoolOrderListLoading = false;
          }
        );
        break;
      case "edit-pool-order":
        this.poolOrderListApi.updatePoolOrderList(
          params,
          () => {
            this.getPoolOrderList();
            this.dialogFormVisible = false;
            this.sumbitPoolOrderListLoading = false;
          },
          () => {
            this.sumbitPoolOrderListLoading = false;
          }
        );
        break;
    }
  }

  private getPoolOrderListFormsParams(mode: string) {
    const params: any = {};

    const showErrorMessage = (msg: string) => {
      if (!!msg) {
        this.$message.error(msg);
      }
    };

    const forms = this.forms;

    const {
      id,
      // name,
      // expired_days,
      // meituanId,
      // timeDuration
      // dataStatistics_time_id,
      // launch_date,

      child,
    } = forms || {};

    const keys = [
      "order_date",
      "first_price",
      "team",
      "source",
      "conversion",
      // "first_price",
      // "second_price",
      // "third_price",
    ];

    const key2EndKey: any = {
      order_date: "order_date",
      first_price: "first_price",
      team: "team",
      source: "source",
      conversion: "conversion",
      // first_price: "first_price",
      // second_price: "second_price",
      // third_price: "third_price",
    };

    const key2ErrMsg: any = {
      // name: "名称不能为空",
      // expired_days: "有效期天数不能为空",
      // timeDuration: "时长不能为空",
      // shop_type: "球桌订单类型不能为空",
      // expired_days_0: "有效天数不能为零",
      // timeDuration_0: "时长不能为零",
      // meituanId: "美团ID不能为空",
      // quantity: "团购核验次数不能为空",
    };

    Object.assign(params, {
      child,
    });


    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];

      if (forms[key]) {
        // const errMsg = key2ErrMsg[`${key}_${forms[key]}`];
        // if (errMsg) {
        //   showErrorMessage(errMsg);

        //   return false;
        // }
        params[key2EndKey[key] || key] = forms[key];
      } else {
        // showErrorMessage(key2ErrMsg[key]);
        // return false;
      }
    }

    // if (typeof dataStatistics_time_id == "number") {
    //   params.dataStatistics_time_id = dataStatistics_time_id;
    // }

    if (mode == "edit-pool-order") {
      params.id = id;
    }

    return params;

    // if (name) {
    //   params.name = name
    // } else {
    //   showErrorMessage('名称不能为空')
    // }

    // if (expired_days) {
    //   params.valid_time = expired_days
    // }

    // if (timeDuration) {
    //   params.value = timeDuration
    // }

    // if (meituanId) {

    // }
  }

  // 统计总计
  // private stat_price: any = "";
  private totalPoolOrderInfo: any = {
    pool_count: 0,
    actual_money: 0,
    count: 0
  }


  //获取优惠券列表
  private getPoolOrderList(): void {
    // this.form.startDate = Util.handleStartTimeAndEndTime(
    //   this.form.time,
    //   false
    // ).startTime;
    // this.form.endDate = Util.handleStartTimeAndEndTime(
    //   this.form.time,
    //   false
    // ).endTime;
    this.poolOrderListApi.getPoolOrderList(
      { ...this.getPoolOrderListParams() },
      (res: any) => {
        // res.data.list

        const {
          count = 0, // 成交单数总计
          order_pool_number = 0, // 桌数总计
          order_actual_price = 0, // 实际金额总计
        } = res.data || {}

        const totalPoolOrderInfo = {
          count,
          pool_count: order_pool_number,
          actual_money: order_actual_price
        }

        const {
          mergeCells,
          tableData,
        } = this.handleComplexList(res.data.list)

        this.mergeCells = mergeCells

        this.tableData = tableData

        this.totalPoolOrderInfo = totalPoolOrderInfo

        // this.stat_price = res.data.stat_price;
        this.tablePage.page = res.data.page;
        this.tablePage.prepage = res.data.prepage;
        // this.tablePage.totalPage = res.data.total;
        this.tablePage.count = res.data.count;
      },
      () => {}
    );
  }

  private handleComplexList(list: any[]) {
    const mergeCells: any[] = []

    const tableData: any[] = []

    let idx: number = 0

    const fixCols: number[] = [0, 7, 8, 9, 10, 13]

    // const childLen: number = 11


    for (const cell of list) {
      const {
        child: children = [],
        ...faOthers
      } = cell || {}

      if (children.length > 0) {
        tableData.push(...children.map((item: any) => {
          const {
            id: child_id
          } = item || {}
          const newItem = {
            child_id,
            ...item,
            ...faOthers,
            _originCell: cell
          }

          return newItem
        }))

        fixCols.forEach((col: number) => {
          mergeCells.push({
            row: idx,
            col,
            rowspan: children.length,
            colspan:1
          })
        })

        idx += children.length
      } else {
        cell._originCell = cell

        tableData.push(cell)
        
        ++idx
      }

    }

    console.log(mergeCells,
      tableData, `mergeCells,
      tableData`)


    return {
      mergeCells,
      tableData
    }
  }

  private getPoolOrderListParams(mode = "all") {
    // mode 可选的值 all  noPageOption exportExcel
    const params: any = {};

    const form = this.form;

    const tablePage = this.tablePage;

    const { page, prepage } = tablePage;

    const {
      time,
    } = form;


    if (Array.isArray(time) && time.length === 2) {
      const [startDate, endDate] = time;

      const startTime = Util.dateTimeUtilnohms(startDate);
      const endTime = Util.dateTimeUtilnohms(endDate);

      params.start_date = startTime;

      params.end_date = endTime;
    }


    // if (
    //   mode == "exportExcel" &&
    //   !(params.gain_date_start && params.gain_date_end)
    // ) {
    //   this.$message.error("请选择日期导出数据");
    //   return null;
    // }

    if (mode === "all") {
      params.page = page;

      params.limit = prepage;
    }

    return params;
  }

  private exportTable() {
    // new HealthWarning().exportTable(this.form);
  }

  private selectPage: any = {
    page: 1,
    prepage: 200,
    totalPage: 0,
    count: 0,
  };

  /** 导出excel */
  // private downloadExcelLoading: boolean = false;

  // // 订单字段名 文案对应关系
  // private orderExcelList2ch: any = {
  //   _bussinessName: "业务线",
  //   customer_no: "客户编号",
  //   customer_name: "客户名称",
  //   phone: "电话",
  //   gain_date: "线索获取日期",
  //   signing_date: "签约日期",
  //   source_name: "线索来源",
  //   sale_name: "销售",
  //   shop_name: "店铺名称",
  //   _pay_list_info: "打款",
  //   // first_price: "首款",
  //   // second_price: "第二笔",
  //   // third_price: "第三笔",
  //   total_price: "累计",
  // };
  // // 订单Excel 表头的先后顺序
  // private orderExcelSortList: string[] = [
  //   "_bussinessName",
  //   "customer_no",
  //   "customer_name",
  //   "phone",
  //   "gain_date",
  //   "signing_date",
  //   "source_name",
  //   "sale_name",
  //   "shop_name",
  //   "_pay_list_info",
  //   // "first_price",
  //   // "second_price",
  //   // "third_price",
  //   "total_price",
  // ];

  // // // 点击导出excel 表格
  // private handleDownloadExcel() {
  //   const params = this.getPoolOrderListParams("exportExcel");

  //   if (params === null) {
  //     return false;
  //   }

  //   this.downloadExcelLoading = true;
  //   this.poolOrderListApi.exportExcelPoolOrderList(
  //     params,
  //     (res: any) => {
  //       const {
  //         gain_date_start: start_date,
  //         gain_date_end: end_date,
  //         signing_date_start,
  //         signing_date_end,
  //         payment_date_start,
  //         payment_date_end,
  //       } = params || {};

  //       const create_time_duration_name =
  //         start_date && end_date
  //           ? ` 线索获取时间${start_date}到${end_date}`
  //           : "";

  //       const signing_time_duration_name =
  //         signing_date_start && signing_date_end
  //           ? ` 签约时间${signing_date_start}到${signing_date_end}`
  //           : "";

  //       const payment_time_duration_name =
  //         payment_date_start && payment_date_end
  //           ? ` 打款时间${payment_date_start}到${payment_date_end}`
  //           : "";
  //       const filename = `市场部球桌订单列表${create_time_duration_name}${signing_time_duration_name}${payment_time_duration_name}.xlsx`;
  //       const { data } = res || {};

  //       const { list: originList } = data || {};

  //       const newList = Array.isArray(originList)
  //         ? originList.map((item) => {
  //             const { type, payment_info_view } = item || {};

  //             const newItem = {
  //               ...(item || {}),

  //               _pay_list_info: createPaymentInfoTxt(payment_info_view),

  //               _bussinessName: bussinessType2Ch[type],
  //             };

  //             return newItem;
  //           })
  //         : [];

  //       if (newList.length > 0) {
  //         const orderExcelSortList = this.orderExcelSortList;

  //         const orderExcelList2ch = this.orderExcelList2ch;

  //         const excelJSONData = getExcelJSONDataByList(
  //           newList,
  //           orderExcelSortList,
  //           orderExcelList2ch
  //         );

  //         excuteDownloadExport2Excel(excelJSONData, filename);
  //       } else {
  //         this.$message.info("选中时段没有可导出的信息");
  //       }

  //       this.downloadExcelLoading = false;
  //     },
  //     () => {
  //       this.downloadExcelLoading = false;
  //     }
  //   );
  // }

  /** end 导出excel */
}
